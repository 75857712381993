'use client';
import React from 'react';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import { UserResponse } from '@bloom/codegen/models/UserResponse';

import ErrorPage from '@bloom/library/components/ErrorPage';

const BypassComponent: React.FC<{ FallbackComponent: React.FC; children: React.ReactNode }> = ({
  children,
}) => <>{children}</>;

const BugsnagErrorBoundary =
  typeof window === 'undefined' || !process.env.BUGSNAG_API_KEY
    ? BypassComponent
    : Bugsnag.getPlugin('react')?.createErrorBoundary(React) || BypassComponent;

const BugsnagProvider: React.FC<React.PropsWithChildren<{ me: UserResponse | null }>> = (props) => {
  const { children, me } = props;

  if (typeof window !== 'undefined' && process.env.BUGSNAG_API_KEY) {
    Bugsnag.start({
      apiKey: process.env.BUGSNAG_API_KEY,
      appVersion: process.env.BLOOM_BUILD_ID,
      maxBreadcrumbs: 10,
      onError: async (event) => {
        if (event.errors[0]?.errorMessage.match(/Minified React error #\d+/)) {
          return false;
        }
        return true;
      },
      plugins: [new BugsnagPluginReact()],
      releaseStage: process.env.APP_ENV,
      user: me ? { email: me.email, id: me.id, name: `${me.firstName} ${me.lastName}` } : undefined,
    });
  }

  return <BugsnagErrorBoundary FallbackComponent={ErrorPage}>{children}</BugsnagErrorBoundary>;
};

export { BugsnagProvider };
