import React from 'react';

import { twMerge } from 'tailwind-merge';

import {
  PolymorphicComponentPropWithRef,
  PolymorphicRef,
} from '@bloom/ui/types/polymorphic-component';

export enum TextVariantEnum {
  ACCENT = 'accent',
  FANCY = 'fancy',
  MEDIUM = 'medium',
  REGULAR = 'regular',
}

export enum TextSizeEnum {
  '4XL' = '4xl',
  '3XL' = '3xl',
  XXL = '2xl',
  LG = 'lg',
  MD = 'md',
  SM = 'sm',
  XS = 'xs',
  INHERIT = 'INHERIT',
  CUSTOM = 'CUSTOM',
}

export type TextPropsType<C extends React.ElementType> = PolymorphicComponentPropWithRef<
  C,
  { size?: TextSizeEnum; variant?: TextVariantEnum }
>;

export type TextComponentType = <C extends React.ElementType = 'p'>(
  props: TextPropsType<C>
) => React.ReactNode | null;

export const Text: TextComponentType = React.forwardRef(
  <C extends React.ElementType = 'p'>(
    props: TextPropsType<C>,
    forwardedRef?: PolymorphicRef<C>
  ) => {
    const {
      as: ComponentTag = 'p',
      className,
      size = TextSizeEnum.SM,
      variant,
      ...restProps
    } = props;

    return (
      <ComponentTag
        className={twMerge(
          variant === TextVariantEnum.ACCENT ? 'font-accent' : '',
          variant === TextVariantEnum.FANCY ? 'font-fancy' : '',
          variant === TextVariantEnum.MEDIUM ? 'font-medium' : '',
          variant === TextVariantEnum.REGULAR ? 'font-regular' : '',

          size === TextSizeEnum.XXL ? 'text-2xl' : '',
          size === TextSizeEnum['3XL'] ? 'text-3xl' : '',
          size === TextSizeEnum.MD ? 'text-base' : '',
          size === TextSizeEnum.INHERIT ? 'text-inherit' : '',
          size === TextSizeEnum.LG ? 'text-lg' : '',
          size === TextSizeEnum.SM ? 'text-sm' : '',
          size === TextSizeEnum.XS ? 'text-xs' : '',

          className
        )}
        ref={forwardedRef}
        {...restProps}
      />
    );
  }
);
