'use client';

import { useState } from 'react';

import { QueryClientProvider } from '@tanstack/react-query';

import { getQueryClient } from '@bloom/library/utils/get-query-client';

function ReactQueryProvider({ children }: React.PropsWithChildren) {
  const [client] = useState(getQueryClient());

  return <QueryClientProvider client={client}>{children}</QueryClientProvider>;
}

export default ReactQueryProvider;
