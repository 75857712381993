import { useEffect, useState } from 'react';

import { twMerge } from 'tailwind-merge';

import { Text, TextSizeEnum, TextVariantEnum } from '@bloom/ui/components/Typography/Text';

const ErrorPage: React.FC<React.PropsWithChildren<{ className?: string; title?: string }>> = (
  props
) => {
  const { children, className, title = 'Something went wrong' } = props;

  const [shouldShowTheErrorContent, setShouldShowTheErrorContent] = useState(false);

  useEffect(() => {
    // const refreshCount = Number(sessionStorage.getItem('bloom_sww_refresh_count')) || 0;

    // if (refreshCount > 0) {
    const url = new URL(window.location.href);
    url.searchParams.set('bloom_sww', 'true');
    window.history.pushState({}, '', url);
    // setShouldShowTheErrorContent(true);
    // } else {
    //   sessionStorage.setItem('bloom_sww_refresh_count', String(refreshCount + 1));
    //   setTimeout(() => {
    //     window.location.reload();
    //   }, 0);
    // }
  }, []);

  // if (shouldShowTheErrorContent) {
  return (
    <div className={twMerge('w-full p-20 text-center', className)}>
      <Text size={TextSizeEnum['3XL']} variant={TextVariantEnum.MEDIUM}>
        {title}
      </Text>

      <div>
        Thanks for noticing &mdash; we're going to fix it up and have things back to normal soon.
      </div>

      {children}
    </div>
  );
  // }

  // return null;
};

export default ErrorPage;
